import { Middleware, configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducers';

import { loadStateFromCookie, saveStateToCookie } from 'utilities/cookieUtils';
import { loadStateFromSessionStorage, saveStateToSessionStorage } from 'utilities/sessionStorageUtils';

// Load the initial state from session storage and cookies
const preloadedState = {
	...loadStateFromSessionStorage(),
	...loadStateFromCookie(),
};

const persistenceMiddleware: Middleware = (store) => (next) => (action) => {
	const result = next(action);

	const state = store.getState() as RootState;

	// Create a copy of the state excluding sensitive data which need to be saved in the cookie.
	const { counter, ...stateForSessionStorage } = state;

	saveStateToCookie({ counter });

	// Save only the none important rest to a session storage
	if (stateForSessionStorage) {
		saveStateToSessionStorage(stateForSessionStorage);
	}

	return result;
};

export const store = configureStore({
	reducer: rootReducer,
	preloadedState,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({ serializableCheck: false }).concat(persistenceMiddleware),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
