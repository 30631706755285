import { ThemeSelector } from '@almbrand/themeselector';
import { PageType } from 'components/PageType';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import useSWR from 'swr';
import styles from './App.module.scss';
import { ThemeNames } from './constantsValues';
import { CONTENT_DELIVERY_API_URL } from './constantsValues/apiStrings';
import { Page as PageContent, PageContext } from './context/PageContext';
import { apiInstance } from './services/Api';
import { purifyApiUrl } from './services/purifyApiUrl';

const App = () => {
	const location = useLocation();

	const { data, error, isLoading } = useSWR(location.pathname, (): any => {
		return apiInstance().get(purifyApiUrl(location.pathname, CONTENT_DELIVERY_API_URL));
	});

	const pageValue = useMemo(() => new PageContent(data), [data]);
	const themeName = ThemeNames[pageValue?.theme];

	return (
		<ThemeSelector themeName={themeName}>
			{error && <div>Error fetching content: {error.message}</div>}
			<div className={styles.App}>
				{isLoading}
				{data && (
					<PageContext.Provider value={pageValue}>
						{/* <Routes>
							<Route
								path='/'
								element={<PageType />}
							></Route>
						</Routes> */}
						<PageType />
					</PageContext.Provider>
				)}
			</div>
		</ThemeSelector>
	);
};

export default App;
