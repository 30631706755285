import { combineReducers } from '@reduxjs/toolkit';
import { DESTROY_SESSION } from './actionTypeConstants';
import counterSlice, { CounterState } from './slices/counterSlice';

export interface State {
	counter: CounterState;
}

// Combine all reducers.
const appReducer = combineReducers({
	counter: counterSlice,
});

export const rootReducer = (state: State | undefined, action: any) => {
	// Clear all data in redux store to initial.
	if (action.type === DESTROY_SESSION) state = undefined;

	return appReducer(state, action);
};
export default rootReducer;
