import { Container } from '@almbrand/container';
import { Grid } from '@almbrand/grid';
import { GridCellComponent } from './GridCellComponent';

export const ContainerLayout: React.FC<{ component: ContentApiModel }> = ({ component }) => {
	const getClassName = (style) => {
		const marginTop = style?.marginTop ? `mt-${style.marginTop}` : '';
		const marginBottom = style?.marginBottom ? `mb-${style.marginBottom}` : '';
		return `${marginTop} ${marginBottom}`.trim();
	};

	const { content } = component ?? {};
	// const { innerBackgroundColor, gridStyle } = style ?? {};
	const { contentId } = content ?? {};

	// const className = getClassName(style);
	// if (content.contentType == 'ReadlineBlock') return <Component {...content} />;
	return (
		<Container
			desktopWidth='fullWidth'
			tabletWidth='fullWidth'
			mobileWidth='fullWidth'
			// backgroundColor={innerBackgroundColor?.cssClass}
			id={contentId}
		>
			<Container
				desktopWidth='1600'
				tabletWidth='1280'
				mobileWidth='744'
				// className={className}
			>
				<Grid container={true}>
					{content?.contentType === 'DefaultContainerBlock' ? (
						content?.mainArea?.items?.map((subItem, subIndex) => {
							return (
								<GridCellComponent
									component={subItem}
									key={subIndex}
									className={'getClassName(subItem?.style)'}
								/>
							);
						})
					) : (
						<GridCellComponent
							component={component}
							className={'className'}
						/>
					)}
				</Grid>
			</Container>
		</Container>
	);
};
